<template>
  <!--begin::Office-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ office.name }}
          <span style="max-width: 350px;"
                class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ office.displayAddress }}</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
        <b-button v-if="isCanEdit"
                  v-b-modal.modal-add-edit
                  @click="onEditOffice"
                  class="btn bg-light-primary font-weight-bolder font-size-sm">
          {{ $t("MANAGEMENT.EDIT_BUTTON") }}
        </b-button>
        <!--end::Button-->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div v-if="isLoading" class="card-body">
      <h5 class="card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.FLOORS_TITLE") }}
        <span v-if="isCanEdit" class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.FLOORS_TEXT_DESC") }}</span>
      </h5>
      <b-button
          v-if="isCanEdit"
          v-b-modal.modal-add-edit-floor
          class="font-size-sm font-weight-bolder my-2"
          variant="outline-secondary">
        {{ $t("MANAGEMENT.ADD_FLOOR_BUTTON") }}
      </b-button>
      <!--begin::Dummy-->
      <template v-if="office.floors.length === 0">
        <div class="justify-content-center row">
          <div class="col-auto d-flex">
            <FloorsImage/>
          </div>
        </div>
        <div class="justify-content-center row">
          <h5 class="col-auto font-weight-bolder text-dark my-5 text-center">{{ $t("MANAGEMENT.NO_FLOOR_YET_TITLE") }}
            <span v-if="isCanEdit" class="d-block font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.NO_FLOOR_YET_TITLE_DESC") }}</span>
            <b-button v-if="isCanEdit"
                      v-b-modal.modal-add-edit-floor
                      class="btn bg-primary text-light font-weight-bolder my-4">{{ $t("MANAGEMENT.ADD_FLOOR_BUTTON") }}
            </b-button>
          </h5>
        </div>
      </template>
      <!--end::Dummy-->
      <!--begin::Table-->
      <div v-if="office.floors.length > 0" class="table-responsive">
        <b-table
            id="floors-table"
            class="table-vertical-center"
            show-empty
            :items="office.floors"
            :fields="fields">
          <template #empty="scope">
            <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
          </template>
          <template #head()="data">
            <span class="pl-0 text-muted font-weight-lighter">{{ data.label }}</span>
          </template>
          <template #head(actions)="data">
            <span class="pl-0 text-muted font-weight-lighter float-right">{{ data.label }}</span>
          </template>
          <template #cell(name)="data">
            <span v-if="isMobile">
              <h6 class="font-weight-bolder text-dark mb-0">{{ data.item.name }}</h6>
            </span>
            <span v-else @click="setAmplitudeEventType('management-floorplan view')">
              <router-link :disabled="!isCanPointFloorPlan"
                           :event="isCanPointFloorPlan ? 'click' : ''"
                           :style="isCanPointFloorPlan ? 'text-decoration: underline': ''"
                  :to="{ name: 'floor-plan-editor', params: { id: data.item.id } }">
                <h6 class="font-weight-bolder text-dark mb-0">{{ data.item.name }}</h6>
              </router-link>
            </span>
          </template>
          <template #cell(actions)="data">
          <span class="float-right">
            <b-button v-if="isCanEdit"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                      @click="onEditFloor(data.item)"
                      v-b-modal.modal-add-edit-floor
                      v-b-tooltip.hover.v-dark="$t('MANAGEMENT.EDIT_TOOLTIP_TEXT')">
              <Edit/>
            </b-button>
            <span @click="setAmplitudeEventType('management-office-floor-plan show')">
              <router-link v-if="isCanFloorPlanEdit"
                           class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                           v-b-tooltip.hover.v-dark="$t('MANAGEMENT.CUSTOM_SPACE_PLAN_TOOLTIP_TEXT')"
                           :to="{ name: 'floor', params: { id: data.item.id } }">
                <Settings/>
              </router-link>
            </span>
            <span @click="setAmplitudeEventType('management-office-floor-plan-resource show')">
              <router-link v-if="isCanResourceEdit"
                           class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                           v-b-tooltip.hover.v-dark="$t('MANAGEMENT.RESOURCES_TOOLTIP_TEXT')"
                           :to="{ name: 'floor-resources', params: { id: data.item.id } }">
                <Resources/>
              </router-link>
            </span>
            <b-button v-if="isCanEdit"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm"
                      @click="onDeleteFloor(data.item)"
                      v-b-modal.modal-delete-floor
                      v-b-tooltip.hover.v-dark="$t('MANAGEMENT.DELETE_TOOLTIP_TEXT')">
              <Trash/>
            </b-button>
          </span>
          </template>
        </b-table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--begin::Add Edit Office Modal-->
    <AddEditOfficeModal
        :method="onEditOffice"
        :citiesOfCountry="citiesOfCountry"
        :onPage="'office'"
        :form="office"
        :isEdit="true"
        @changeSelectedCountry="newCitiesList"
        @changeIsEdit="toggleIsEdit"/>
    <!--end::Add Edit Office Modal-->
    <!--begin::Add Edit Floor Modal-->
    <AddEditFloorModal
        :onPage="'office'"
        :form="form"
        :isEdit="isEdit"
        @changeIsEdit="toggleIsEdit"
        @changeForm="clearForm"/>
    <!--end::Add Edit Floor Modal-->
    <!--begin::Delete Floor Modal-->
    <b-modal
        id="modal-delete-floor"
        centered>
      <template #modal-header="{ close }">
        <div class="w-100">
          <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.DELETE_FLOOR_TITLE") }}</h5>
          <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
        </div>
      </template>
      <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.DELETE_FLOOR_DESC") }}</h5>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="light-primary"
              class="float-left font-weight-bolder"
              @click="resetModal">
            {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
          </b-button>
          <p class="float-right">
            <b-button
                class="font-weight-bolder"
                variant="danger"
                @click="handleDelete">
              {{ $t("MANAGEMENT.DELETE_BUTTON") }}
            </b-button>
          </p>
        </div>
      </template>
    </b-modal>
    <!--begin::Delete Floor Modal-->
  </div>
  <!--end::Office-->
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { DELETE_FLOOR, GET_OFFICE } from "@/core/services/store/management.module";
import { sendAmplitudeEvent } from "@/core/services/amplitude.service";

import FloorsImage from "@/view/layout/svg/FloorsImage";
import Edit from "@/view/layout/svg/Edit";
import Settings from "@/view/layout/svg/Settings";
import Resources from "@/view/layout/svg/Resources";
import Trash from "@/view/layout/svg/Trash";
import AddEditOfficeModal from "@/view/layout/modal/AddEditOfficeModal";
import AddEditFloorModal from "@/view/layout/modal/AddEditFloorModal";

export default {
  name: "Office",
  components: {
    FloorsImage,
    Edit,
    Settings,
    Resources,
    Trash,
    AddEditOfficeModal,
    AddEditFloorModal
  },
  mounted() {
    this.$store.dispatch(GET_OFFICE, this.$route.params.id).then(() => {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("MANAGEMENT.OFFICES_TITLE"), route: "/management/offices" },
        { title: `${this.office.name}` }
      ]);
      this.isLoading = true;
    });
  },
  created() {
    this.isMobile = this.deviceType() === 'mobile';
  },
  data() {
    return {
      isMobile: false,
      isEdit: false,
      isLoading: false,
      form: {
        name: ''
      },
      nameState: null,
      fields: [
        { key: 'name', label: this.$t("MANAGEMENT.FLOORS_TABLE_TITLE") },
        { key: 'actions', label: this.$t("MANAGEMENT.ACTIONS_TABLE_TITLE") }
      ],
      deleteFormId: null,
      citiesOfCountry: null
    }
  },
  computed: {
    ...mapGetters([
      "office",
      "countryCityList",
      "timezonesList"
    ]),
    isCanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[1]));
        return role !== undefined;
      }
    },
    isCanFloorPlanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[2]));
        return role !== undefined;
      }
    },
    isCanResourceEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[3]));
        return role !== undefined;
      }
    },
    isCanPointFloorPlan() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[4]));
        return role !== undefined;
      }
    }
  },
  methods: {
    setAmplitudeEventType(eventType) {
      sendAmplitudeEvent(eventType);
    },
    deviceType() {
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)) {
        return "tablet";
      }
      else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent)) {
        return "mobile";
      }
      return "desktop";
    },
    newCitiesList(variable) {
      this.citiesOfCountry = this.countryCityList.find(country => country.id === variable);
      this.citiesOfCountry = this.citiesOfCountry.cities;
    },
    toggleIsEdit(variable) {
      this.isEdit = variable;
    },
    clearForm(variable) {
      this.form = variable;
    },
    onEditOffice() {
      // this.isEdit = true;
      if (this.countryCityList.length !== undefined && this.office !== undefined) {
        let country = this.countryCityList.find(country => country.country === this.office.address.country.name);
        if (country) {
          this.office.address.country = country.id;
          let city = country.cities.find(city => city.name === this.office.address.city.name);
          this.office.address.city = city.id;
          this.citiesOfCountry = country.cities;
        }
      }
      if (this.timezonesList.length > 0 && this.office !== undefined) {
        let timezone = this.timezonesList.find(item => item.name.includes(this.office.timezone.slice(-12)));
        if (timezone) this.office.timezone = timezone.id;
      }
    },
    onEditFloor(item) {
      this.isEdit = true;
      this.form = item;
    },
    onDeleteFloor(item) {
      this.deleteFormId = { floorId: item.id };
    },
    resetModal() {
      this.deleteFormId = null;
      this.$bvModal.hide('modal-delete-floor');
    },
    handleDelete() {
      this.$store.dispatch(DELETE_FLOOR, { form: this.deleteFormId, id: this.office.id })
          .then(() => {
            localStorage.removeItem("floorFilter");
            this.$store.dispatch(GET_OFFICE, this.$route.params.id)
            this.deleteFormId = null
          });
      this.$nextTick(() => {
        this.$bvModal.hide('modal-delete-floor');
      })
    }
  }
}
</script>

<style scoped>

</style>
